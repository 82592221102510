import React, { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Success from './Success';
import Image from 'next/image'
import styled, { keyframes } from 'styled-components';
import { slideInUp, fadeInRight } from 'react-animations';
import { XIcon } from '@heroicons/react/outline';

const slideInUpAnimation = keyframes`${slideInUp}`;
const fadeInRightAnimation = keyframes`${fadeInRight}`;

const SlideInUpDiv = styled.div`
  animation: 1s ${slideInUpAnimation};
`;

const FadeInRightDiv = styled.div`
  animation: 1s ${fadeInRightAnimation};
`;


export default function StepModal(props) {

    const cancelButtonRef = useRef(null)

    const [dataArray, setDataArray] = useState({
        first_name: "",
        last_name: "",
        email_address: "",
        phone_number: "",
        state: "",
        zip_code: "",
        lawsuit: props.lawsuit,
        agree: false,
        agree_provide_records: false,
        not_coached_compensated: false,
        cancer_type: [],
        cancer_diagnosis_year: (new Date()).getFullYear(),
        additional: ""
    })

    const updateFieldValue = (name, value) => {
        let newDataArray = { ...dataArray };
        newDataArray[name] = value
        setDataArray(newDataArray)
        // console.log("updated", value)
    }


    const [currentStep, setCurrentStep] = useState(1)

    const prevStep = (step) => {
        setCurrentStep(step - 1)
    }

    const nextStep = (step) => {
        setCurrentStep(step + 1)
    }


    const steps = () => {
        switch (currentStep) {
            case 1:
                return (
                    <Step1
                        nextStep={nextStep}
                        updateFieldValue={updateFieldValue}
                        injuries={props.injuries}
                        product={props.product}
                        cancer_diagnosis={dataArray.cancer_diagnosis}
                        cancer_type={dataArray.cancer_type}
                        form="ModalStepForm"
                    />
                )
            case 2:
                return (
                    <Step2
                        prevStep={prevStep}
                        nextStep={nextStep}
                        updateFieldValue={updateFieldValue}
                        cancer_diagnosis_year={dataArray.cancer_diagnosis_year}
                        cancer_type={dataArray.cancer_type}
                        form="ModalStepForm"
                    />
                )
            // case 3:
            //     return (
            //         <Step3
            //             prevStep={prevStep}
            //             nextStep={nextStep}
            //             updateFieldValue={updateFieldValue}
            //             state={dataArray.state}
            //             zip_code={dataArray.zip_code}
            //             form="ModalStepForm"

            //         />
            //     )
            case 3:
                return (
                    <Step4
                        prevStep={prevStep}
                        nextStep={nextStep}
                        updateFieldValue={updateFieldValue}
                        first_name={dataArray.first_name}
                        last_name={dataArray.last_name}
                        phone_number={dataArray.phone_number}
                        email_address={dataArray.email_address}
                        source={props.source}
                        dataArray={dataArray}
                        agree={dataArray.agree}
                        setSuccess={props.setSuccess}
                        form="ModalStepForm"
                    />
                )
            // case 4:
            //     return (
            //         <Step5
            //             source={props.source}
            //             prevStep={prevStep}
            //             nextStep={nextStep}
            //             updateFieldValue={updateFieldValue}
            //             dataArray={dataArray}
            //             additional={dataArray.additional}
            //             agree={dataArray.agree}
            //             form="ModalStepForm"
            //             setSuccess={props.setSuccess}
            //         // did={did} 
            //         />
            //     )
            case 4:
                return (
                    <Success
                        form="ModalStepForm"
                    // did={did} 
                    />
                )
            // never forget the default case, otherwise VS code would be mad!
            default:
            // do nothing
        }
    }

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={props.setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity w-screen" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto w-screen">
                    <div className="md:flex min-h-full md:items-center md:justify-center md:p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden bg-white text-left shadow-xl transition-all max-w-4xl">
                                <div className="bg-white">
                                    <div className="md:grid grid-rows-3 grid-flow-col">
                                        <div className='row-span-3 lg:w-96 bg-sky-800 p-5 md:p-10 text-white relative'>
                                            <div onClick={() => props.setOpen(!props.open)} className="absolute right-1 top-1 h-10 w-10 rounded-full group p-1 bg-opacity-75 bg-sky-700 hover:bg-sky-600">
                                                <XIcon className='text-white group-hover:text-gray-400 h-8 w-8' />
                                            </div>
                                            <div className='my-auto text-center md:text-left'>
                                                <Dialog.Title as="h3" className="font-serif text-2xl md:text-3xl font-medium text-white leading-6">
                                                    See If You Qualify for a {props.lawsuit}
                                                </Dialog.Title>
                                                <p className='md:mt-10 text-base text-white'>If you or a loved one were injured after {props.product === "Camp Lejeune" ? `drinking the water at ${props.product}` : `using ${props.product}`}, you may be entitled to compensation. <br /> <br /> Get a FREE case review today.</p>
                                                {/* <div className='bg-red-600 mb-5 md:mb-20 text-sm md:text-lg text-center text-white rounded-sm p-2 font-bold tracking-wider'>
                                                    Just Five Questions To See If You Qualify
                                                </div> */}
                                                <div className="md:bottom-10 md:absolute ">
                                                    <Image
                                                        src={`/legalcanary-logo.png`}
                                                        height='45'
                                                        width='150'
                                                        objectFit='contain'
                                                        alt="thelegalcanary.com"
                                                    />
                                                </div>
                                            </div>

                                        </div>

                                        <div className="row-span-3 text-center sm:text-left">

                                            {currentStep != 5 &&
                                                <div className="w-full bg-gray-200 h-4">
                                                    <div className="bg-teal-500 h-4"
                                                        style={
                                                            currentStep === 1 ? { width: '25%' }
                                                                : currentStep === 2 ? { width: '50%' }
                                                                    : currentStep === 3 ? { width: '75%' }
                                                                        : currentStep === 4 ? { width: '99%' }
                                                                            : { width: '100%' }}></div>
                                                </div>
                                            }
                                            <div className="md:ml-4 px-2 md:pt-10 relative md:p-4 lg:p-10 sm:mt-0 md:mt-3 my-auto">
                                                {/* {currentStep === 2 && <SlideInUpDiv className=' text-sm absolute italic top-5 text-green-600 font-semibold'>Looks good! Keep going!</SlideInUpDiv>}
                                                {currentStep === 3 && <FadeInRightDiv className=' text-sm absolute italic top-5 text-green-600 font-semibold'>Got it! You&apos;re more than halfway done!</FadeInRightDiv>}
                                                {currentStep === 4 && <SlideInUpDiv className=' text-sm absolute italic top-5 text-green-600 font-semibold'>You&apos;re almost done! Keep going!</SlideInUpDiv>}
                                                {currentStep === 5 && <FadeInRightDiv className=' text-sm absolute italic top-5 text-green-600 font-semibold'>You&apos;re on the last step!</FadeInRightDiv>} */}
                                                <form className='py-10'>
                                                    {steps()}
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
import React, { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import Image from 'next/image'
import { useRouter } from 'next/router'
import { ArrowRightIcon, XIcon } from '@heroicons/react/outline'
import styled, { keyframes } from 'styled-components';
import { pulse } from 'react-animations';


const pulseAnimation = keyframes`${pulse}`;


const PulseDiv = styled.div`
  animation: 1s ${pulseAnimation};
`;

export default function ScrollModal(props) {

    const router = useRouter()

    const cancelButtonRef = useRef(null)

    const handleModal = () => {
        if (router.pathname.includes('eligibility')) {
            props.setOpen(false)
        } else {
            props.setOpen(false)
            props.setOpenForm(true)
        }
    }

    const closeTab = () => {
        props.setOpen(false)
    };

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={props.setOpen}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity w-screen" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 overflow-y-auto w-screen">
                    <div className="md:flex min-h-full md:items-center md:justify-center md:p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden bg-white shadow-xl transition-all max-w-4xl">
                                <div className="bg-white">
                                    <div className="">
                                        <div className=' bg-sky-800 px-5 pt-5 pb-2 md:px-10 md:pt-10 text-white'>
                                            <div onClick={() => closeTab()} className="absolute right-1 top-1 h-10 w-10 rounded-full group p-1 bg-opacity-75 bg-sky-700 hover:bg-sky-600">
                                                <XIcon className='text-white group-hover:text-gray-400 h-8 w-8' />
                                            </div>
                                            <div className='my-auto text-center'>
                                                <div className="">
                                                    <Image
                                                        src={`/legalcanary-logo.png`}
                                                        height='45'
                                                        width='150'
                                                        objectFit='contain'
                                                        alt="thelegalcanary.com"
                                                    />
                                                </div>
                                                <PulseDiv>
                                                    <Dialog.Title as="h3" className="mt-3 font-serif text-5xl md:text-6xl font-medium text-white">
                                                        Not sure if you qualify?
                                                    </Dialog.Title>
                                                </PulseDiv>
                                                <h2 className="mt-10 text-xl max-w-xl mx-auto md:text-3xl md:px-10 font-medium text-white leading-6">
                                                    Use the free <span className='uppercase underline'>1 min. Eligibility Check</span> to see if you qualify for a case review
                                                </h2>
                                                <p className='mt-2 text-lg text-white max-w-xl mx-auto italic px-10'>See if you qualify in 1 minute or less!</p>
                                            </div>
                                        </div>
                                        <div className='bg-white p-5 md:p-10'>
                                            <div>
                                                {/* <p className='mb-2 font-bold  text-white text-lg text-center'>Call NOW</p> */}
                                                <div onClick={() => handleModal()} className='group my-5 md:my-0 w-full md:w-1/2 mx-auto text-white  bg-green-600 hover:bg-green-500 text-2xl tracking-widest cursor-pointer font-bold rounded-sm uppercase flex items-center justify-center px-6 py-4'>
                                                    {router.pathname.includes('eligibility') ? "Continue" : "Start Quiz"} <ArrowRightIcon className='transition transform  ease-out group-hover:ease-in motion-reduce:transition-none motion-reduce:hover:transform-none group-hover:-translate-x-2 ml-2 h-6 w-6' />
                                                </div>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                {/* <div onClick={() => closeTab()} className='bg-gray-300 group my-0 w-full mx-auto text-gray-900 hover:underline text-sm cursor-pointer font-bold uppercase flex items-center justify-center px-6 py-4'>
                                    <XIcon className='transition transform  ease-out group-hover:ease-in motion-reduce:transition-none motion-reduce:hover:transform-none group-hover:-translate-x-2 mr-2 h-4 w-4' /> Close
                                </div> */}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
import { data } from 'autoprefixer';
import React, { useState } from 'react'
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import Step5 from './Step5';
import Success from './Success';
import styled, { keyframes } from 'styled-components';
import { slideInUp, fadeInRight } from 'react-animations';

const slideInUpAnimation = keyframes`${slideInUp}`;
const fadeInRightAnimation = keyframes`${fadeInRight}`;

const SlideInUpDiv = styled.div`
  animation: 1s ${slideInUpAnimation};
`;

const FadeInRightDiv = styled.div`
  animation: 1s ${fadeInRightAnimation};
`;

export default function StepFormV2(props) {
    const [dataArray, setDataArray] = useState({
        first_name: "",
        last_name: "",
        email_address: "",
        phone_number: "",
        state: "",
        zip_code: "",
        lawsuit: props.lawsuit,
        agree: false,
        agree_provide_records: false,
        not_coached_compensated: false,
        cancer_type: [],
        cancer_diagnosis_year: (new Date()).getFullYear(),
        additional: ""
    })

    const updateFieldValue = (name, value) => {
        let newDataArray = { ...dataArray };
        newDataArray[name] = value
        setDataArray(newDataArray)
        // console.log("updated", value)
    }

    // console.log(dataArray)

    const [currentStep, setCurrentStep] = useState(1)

    const prevStep = (step) => {
        setCurrentStep(step - 1)
    }

    const nextStep = (step) => {
        setCurrentStep(step + 1)
    }


    const steps = () => {
        switch (currentStep) {
            case 1:
                return (
                    <Step1
                        nextStep={nextStep}
                        updateFieldValue={updateFieldValue}
                        injuries={props.injuries}
                        product={props.product}
                        cancer_diagnosis={dataArray.cancer_diagnosis}
                        cancer_type={dataArray.cancer_type}
                        form="BottomStepForm"
                    />
                )
            case 2:
                return (
                    <Step2
                        prevStep={prevStep}
                        nextStep={nextStep}
                        updateFieldValue={updateFieldValue}
                        cancer_diagnosis_year={dataArray.cancer_diagnosis_year}
                        cancer_type={dataArray.cancer_type}
                        form="BottomStepForm"
                    />
                )
            // case 3:
            //     return (
            //         <Step3
            //             prevStep={prevStep}
            //             nextStep={nextStep}
            //             updateFieldValue={updateFieldValue}
            //             state={dataArray.state}
            //             zip_code={dataArray.zip_code}
            //             form="BottomStepForm"

            //         />
            //     )
            case 3:
                return (
                    <Step4
                        prevStep={prevStep}
                        nextStep={nextStep}
                        updateFieldValue={updateFieldValue}
                        first_name={dataArray.first_name}
                        last_name={dataArray.last_name}
                        phone_number={dataArray.phone_number}
                        email_address={dataArray.email_address}
                        source={props.source}
                        dataArray={dataArray}
                        agree={dataArray.agree}
                        setSuccess={props.setSuccess}
                        form="BottomStepForm"
                    />
                )
            // case 4:
            //     return (
            //         <Step5
            //             source={props.source}
            //             prevStep={prevStep}
            //             nextStep={nextStep}
            //             updateFieldValue={updateFieldValue}
            //             dataArray={dataArray}
            //             additional={dataArray.additional}
            //             agree={dataArray.agree}
            //             form="BottomStepForm"
            //             setSuccess={props.setSuccess}
            //         // did={did} 
            //         />
            //     )
            case 4:
                return (
                    <Success
                        form="BottomStepForm"
                    // did={did} 
                    />
                )
            // never forget the default case, otherwise VS code would be mad!
            default:
            // do nothing
        }
    }

    return (
        //form at bottom of lawsuit page in blue border
        <div className='max-w-xl mx-auto relative pt-10 px-4 md:px-12'>
            {/* {currentStep === 2 && <SlideInUpDiv className='absolute italic text-sm top-3 text-green-600 font-semibold'>Looks good! Keep going!</SlideInUpDiv>}
            {currentStep === 3 && <FadeInRightDiv className='absolute italic text-sm top-3 text-green-600 font-semibold'>Got it! You&apos;re more than halfway done!</FadeInRightDiv>}
            {currentStep === 4 && <SlideInUpDiv className='absolute italic text-sm top-3 text-green-600 font-semibold'>You&apos;re almost done! Keep going!</SlideInUpDiv>}
            {currentStep === 5 && <FadeInRightDiv className='absolute italic text-sm top-3 text-green-600 font-semibold'>You&apos;re on the last step!</FadeInRightDiv>} */}
            {currentStep != 5 &&
                <div className="w-full bg-gray-200 rounded-full h-5">
                    <div className="bg-teal-500 rounded-full h-5"
                        style={
                            currentStep === 1 ? { width: '25%' }
                                : currentStep === 2 ? { width: '50%' }
                                    : currentStep === 3 ? { width: '75%' }
                                        : currentStep === 4 ? { width: '99%' }
                                            : { width: '100%' }}></div>
                </div>}
            <div>
                <form className='py-4 md:py-12'>
                    {steps()}
                </form>
            </div>
        </div>
    )

}
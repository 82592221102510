import React, { useState } from 'react';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/outline';
import parse from "html-react-parser";


export default function AccordionItem(props) {
    const [isActiveTab, setIsActiveTab] = useState(false);

    return (
        <div>
            <div onClick={() => setIsActiveTab(!isActiveTab)}
                className="
                           space-y-6 p-2 
                            text-gray-900 
                            hover:bg-gray-200
                            cursor-pointer 
                            pt-5
                            rounded-md">
                <div className="flex justify-between">
                    <div className='font-semibold text-lg text-gray-700'>
                        {parse(props.item.question)}
                    </div>

                    <div className='text-gray-400 font-black'>
                        {isActiveTab ? <ChevronUpIcon className='h-5 w-5' /> : <ChevronDownIcon className='h-5 w-5' />}
                    </div>
                </div>
            </div>
            {isActiveTab &&
                <div className='bg-white shadow -mt-3 rounded-b-lg'>
                    <div className='md:p-15 p-10 text-lg text-gray-700 text-left'>
                        {parse(props.item.answer)}
                        <p><a className='font-semibold' href={`${props.href}`}>Contact us</a> to see if you qualify for the {props.title} lawsuit.</p>
                    </div>

                </div>
            }
        </div>
    );
}
